@import "styles/config.scss";

.app {
    width: 100%;
    height: 100%;

    &__wrapper {
        height: 100%;
    }

    &__header {
        @include rem(margin-bottom, 50px);
    }

    &__title {
        display: block;

        @include breakpoint(md) {
            @include rem(font-size, 36px);
        }
    }

    &__form {
        display: block;
        height: 100%;
    }

    &__pagination {
        @include rem(margin-top, 30px);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
