@import "styles/config.scss";

.quiz {
    width: 100%;
    height: 100%;
    
    &__steps {
        width: 100%;
        height: 100%;
    }

    &__step {
        height: 100%;
    }
}