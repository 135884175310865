@mixin myriad($weight: 400) {
    @if ($weight == 300) {
        font-family: "Myriad-Light-Semi-Condensed", sans-serif;
        font-weight: 300;
    } @else if($weight == 700) {
        font-family: "Myriad-Bold-Semi-Condensed", sans-serif;
        font-weight: 700;
    } @else {
        font-family: "Myriad-Semi-Condensed", sans-serif;
        font-weight: 400;
    }
}