@import "styles/config.scss";

.progress {
    @include rem(padding, 0 50px);
    @include rem(margin-bottom, 30px);
    display: flex;
    flex-direction: row;
    align-items: center;

    &__text {
        @include rem(margin-right, 10px);
        @include rem(font-size, 18px);
        font-weight: 400;
        margin-bottom: 0;
    }

    &__bar {
        @include rem(height, 15px);
        position: relative;
        width: 100%;
        background-color: $color-gray-26;
    }

    &__progress {
        @include rem(height, 15px);
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-green;
    }
}