@import "styles/config.scss";

.recommendation {
    @include rem(padding, 50px);
    display: block;
    min-height: 55vh;
    transition-property: height;
    transition-timing-function: linear;
    transition-duration: 0.5s;

    &___lbg,
    &___llp,
    &___plc,
    &___nonres,
    &___eseller,
    &___lbs2,
    &___lbs5 {
        display: block;
    }

    &___lbs1 {
        .recommendation__content {
            min-height: 217px;
        }
    }

    &___lbs3 {
        .recommendation__content {
            min-height: 315px;
        }
    }

    &___lbs4 {
        .recommendation__content {
            min-height: 310px;
        }
    }

    &__columns {
        display: flex;
        flex-direction: column;
        width: 100%;

        &___one {
            max-width: 100%;
        }

        &___two {
            display: grid;
            grid-template-columns: 1fr 15px 1fr;
            grid-gap: 50px;
        }
    }

    &__divider {
        @include rem(margin, 30px 0);
        width: 1px;
        height: 100%;
        background-color: #ccc;

        @include breakpoint(lg) {
            margin: 0;
        }
    }

    &__title {
        @include rem(margin-bottom, 30px);
        @include rem(font-size, 28px);
        text-align: center;
    }

    &__header {
        @include rem(margin-bottom, 15px);
        display: flex;
        flex-direction: column;
    }

    &__package {
        @include rem(font-size, 28px);
        text-align: center;
        color: $color-green;
    }

    &__price {
        @include rem(font-size, 28px);
        @include rem(margin-bottom, 10px);
        font-weight: 600;
        text-align: center;
    }

    &__content {
        @include rem(margin-bottom, 30px);
        max-width: 65%;
        min-height: 20vh;
        margin: 0 auto;
        text-align: center;

        p {
            margin-bottom: 1.5rem;
        }

        ul,
        ol {
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5rem!important;
            padding-left: 0;

            li {
                grid-gap: 15px;
                line-height: 1.2;
                align-items: flex-start;

                svg {
                    margin-top: 0.1rem;
                    font-size: 1.2rem !important;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__button {
        @include rem(min-width, 135px);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        line-height: 1.125;
        text-align: center;
        vertical-align: center;
        text-decoration: none;
        background-color: transparent;
        border: 1px solid transparent;
        color: $color-white;
        padding: 0.75rem 1.25rem;
        -webkit-appearance: none;
        transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

        &___buy {
            @include rem(margin-bottom, 10px);
            background-color: $color-green;
            border-color: $color-green;
            color: $color-white;

            &:hover,
            &:active {
                background-color: lighten($color-green, 5%);
                border-color: lighten($color-green, 5%);
                color: $color-white;
                text-decoration: none;
            }
        }

        &___details {
            background: $color-white;
            color: $color-font;
            border: 1px solid $color-gray-26;

            &:hover,
            &:active {
                border: 1px solid $color-gray-23;
                background: $color-white;
            }
        }
    }
}

.loader {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}