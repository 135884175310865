@import "styles/config.scss";

.question {
    @include rem(padding, 50px);
    display: block;
    min-height: 55vh;
    transition-property: height;
    transition-timing-function: linear;
    transition-duration: 0.5s;

    &__header {
        @include rem(min-height, 150px);
        @include rem(margin-bottom, 30px);
        display: grid;
        grid-template-columns: 30px auto;
        align-items: baseline;
    }

    &__number {
        @include rem(font-size, 32px);
        @include myriad(400);
        color: $color-font-headline;
    }

    &__question {
        @include rem(font-size, 32px);
        @include myriad(400);
        display: flex;
        align-items: baseline;
    }

    &__helpText {
        display: block;

        p {
            font-size: 1.115rem;
        }
    }

    &__options {
        @include rem(margin-bottom, 30px);
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: 1fr 1fr;
        }
    }

    &__option {
        @include rem(padding, 20px 15px);
        @include rem(margin-bottom, 15px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background-color: $color-white;
        transition: border 0.5s;
        border: 2px solid $color-gray-11;

        @include breakpoint(md) {
            margin-bottom: 0;
        }

        &___selected {
            border-color: $color-green;
            background-color: $color-green;

            &:hover {
                border-color: $color-green!important;
                background-color: $color-green!important;
            }

            h3 {
                color: $color-white;

                strong {
                    color: $color-white!important;
                }
            }
        }

        &:hover {
            cursor: pointer;
            border-color: $color-gray-40;
        }

        h3 {
            @include rem(font-size, 18px);
            font-weight: 400;
            margin-bottom: 0;

            strong {
                font-weight: 400;
                color: $color-gray-57;
            }
        }
    }

    &__progress {
        @include rem(margin-bottom, 30px);
    }
    
    &__tooltip {
        @include myriad(400);
        font-size: 1.125rem!important;
    }
}
