@import "styles/config.scss";

.markdown {
    &Box {
        @include rem(padding, 3px 10px);
        @include rem(font-size, 21px);
        @include border-radius(4px);
        display: inline-block;
        color: $color-white;
    }

    &List {
        padding-left: 0;
        list-style-type: none;

        &__item {
            @include rem(margin-bottom, 7px);
            display: grid;
            grid-template-columns: max-content auto;
            grid-gap: 8px;
            align-items: center;

            svg {
                position: relative;
                width: 1em !important;
                font-size: initial !important;
            }
        }
    }
}
