$color-info: #3498db !default;
$color-success: #2ecc71 !default;
$color-danger: #e74c3c !default;
$color-warning: #f39c12 !default;

$color-blue: #31a5cb;
$color-blue-dark: #0c6a9f;
$color-black: #000;
$color-green: #62a43f;
$color-white: #fff;

$color-gray-01: #fafafa;
$color-gray-02: #fcfcfc;
$color-gray-03: #f7f7f7;
$color-gray-04: #f4f4f4;
$color-gray-05: #f1f1f1;
$color-gray-06: #fbfbfb;
$color-gray-07: #f0f0f0;
$color-gray-09: #e5e5e5;
$color-gray-11: #d7d7d7;
$color-gray-13: #dedbdb;
$color-gray-18: #dcdcdc;
$color-gray-21: #d3d3d3;
$color-gray-23: #c7c7c7;
$color-gray-26: #bcbaba;
$color-gray-40: #88919a;
$color-gray-45: #676767;
$color-gray-57: #6d6d6d;
$color-gray-75: #4b4b4b;
$color-gray-79: #333333;
$color-gray-81: #313131;
$color-gray-82: #2e2e2e;

$color-font: #333;
$color-font-headline: #333;
$color-background: $color-white;
$color-placeholder: #ddd;

$color-border: #dadada;
$color-border-dark: #ccc;

$color-link: #459bd9;
$color-link-visited: #459bd9;
$color-link-hover: #7db9e5;
$color-link-active: #459bd9;
