@import "styles/config.scss";

.introduction {
    @include rem(padding, 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__logo {
        @include rem(margin-bottom, 30px);
        @include rem(max-height, 50px);
    }

    &__title {
        @include rem(font-size, 38px);
        @include myriad(400);
        @include rem(margin-bottom, 10px);
    }

    &__subtitle {
        @include rem(font-size, 26px);
        @include myriad(400);
        @include rem(margin-bottom, 30px);
    }

    &__icon {
        @include rem(margin-top, 15px);
        color: $color-font-headline;
    }
}
